import debounce from 'lodash/debounce';

const body = document.getElementsByTagName('body')[0];

let toggleMenu = () => {
  console.log('menu toggle');
  if (!body.classList.contains('menu-open')) {
    body.classList.add('menu-open');
  } else {
    body.classList.remove('menu-open');
  }
};
const menuBt = document.getElementById('nav-toggle');

if (menuBt) {
  menuBt.addEventListener('click', toggleMenu);
}

let mobileMenuCloseCheck = () => {
  console.log('mobileMenuCloseCheck', window.innerWidth);
  if (window.innerWidth >= 1024) {
    if (body.classList.contains('menu-open')) {
      body.classList.remove('menu-open');
    }
  }
};

window.addEventListener(
  'resize',
  debounce(mobileMenuCloseCheck, 150),
);
