// @ts-ignore
import Flickity from 'flickity';

let carousel = document.getElementsByClassName('carousel-block');

if (carousel.length) {
  let childrenInCarousel = carousel[0].childElementCount;
  let theCarouselBlock =
    document.getElementsByClassName('carousel-block')[0];

  if (childrenInCarousel >= 3) {
    new Flickity(theCarouselBlock, {
      imagesLoaded: true,
      wrapAround: true,
      groupCells: true,
      pageDots: false,
    });
  }
}
